import { render, staticRenderFns } from "./confirmation-modal.vue?vue&type=template&id=130bda7c&"
import script from "./confirmation-modal.vue?vue&type=script&lang=js&"
export * from "./confirmation-modal.vue?vue&type=script&lang=js&"
import style0 from "./confirmation-modal.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports