import i18n from 'i18n'

export const ondemand = [
  {
    label: i18n.t('content_type.rail'),
    value: 'rail'
  },
  {
    label: i18n.tc('content_type.flight'),
    value: 'flight'
  },
  {
    label: i18n.tc('content_type.rental'),
    value: 'rental'
  },
  {
    label: i18n.tc('content_type.hotel'),
    value: 'hotel'
  },
  {
    label: i18n.tc('content_type.eurostar'),
    value: 'eurostar'
  },
  {
    label: i18n.t('parking'),
    value: 'parking'
  },
  {
    label: i18n.t('lounge.lounge'),
    value: 'lounge'
  },
  {
    label: i18n.t('chauffeur'),
    value: 'chauffeur'
  },
  {
    label: i18n.tc('content_type.taxi'),
    value: 'ridehailing'
  },
  {
    label: i18n.t('content_type.carclub'),
    value: 'carclub'
  },
  {
    label: i18n.t('content_type.onwardtravel'),
    value: 'onwardtravel'
  },
  {
    label: i18n.t('content_type.bikehire'),
    value: 'bikehire'
  }
]

export const other = [
  {
    label: i18n.t('fuel'),
    value: 'fuel'
  },
  {
    label: i18n.tc('restaurant'),
    value: 'restaurant'
  },
  {
    label: i18n.t('theatre'),
    value: 'theatre'
  },
  {
    label: i18n.tc('content_type.bus'),
    value: 'bus'
  },
  {
    label: i18n.t('content_type.bicycle'),
    value: 'bicycle'
  },
  {
    label: i18n.t('content_type.tube'),
    value: 'tube'
  },
  {
    label: i18n.t('driving'),
    value: 'driving'
  },
  {
    label: i18n.t('other'),
    value: 'other'
  }
]

export default [].concat(ondemand, other)
