<template>
  <q-page>
    <!-- RIGHt HAND SIDEBAR -->
    <q-drawer
      v-model="sidebarOpen"
      content-class="bg-white shadow-2"
      side="right"
      :width="325"
    >
      <flow v-if="request" :request="request" />
      <q-list v-if="request" class="key-info" no-border>
        <q-item
          v-if="request.organisation"
          class="item"
        >
          <q-item-section avatar>
            <q-avatar
              inverted
              icon="update"
              class="bg-primary text-white"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label label>
              {{ request.status }}
            </q-item-label>
            <q-item-label caption>
              Status
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item class="item">
          <q-item-section avatar>
            <m-icons-avatar
              :type="request.content_type === 'other' ? 'concierge' : request.content_type"
              size="inherit"
              inverted
            />
          </q-item-section>
          <q-item-section>
            <q-item-label
              class="capitalize"
              label
            >
              {{ contentName }} {{ request.type }} Request
            </q-item-label>
            <q-item-label caption>
              {{ request.reference }}
            </q-item-label>
          </q-item-section>
        </q-item>

        <q-item
          v-if="request.organisation"
          class="item"
        >
          <q-item-section avatar>
            <q-avatar
              inverted
              icon="business"
              class="bg-primary text-white"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label label>
              {{ request.organisation.name }}
            </q-item-label>
            <q-item-label caption>
              Organisation
            </q-item-label>
          </q-item-section>
        </q-item>

        <q-item class="item">
          <q-item-section avatar>
            <q-avatar
              inverted
              icon="receipt"
              class="bg-primary text-white"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label label>
              {{ fee }}
            </q-item-label>
            <q-item-label caption>
              Fee
            </q-item-label>
          </q-item-section>
        </q-item>

        <q-item class="item">
          <q-item-section avatar>
            <q-avatar>
              <img :src="request.requester.picture">
            </q-avatar>
          </q-item-section>
          <q-item-section>
            <q-item-label label>
              {{ request.requester.display_name }}
            </q-item-label>
            <q-item-label
              v-if="request.requester.display_name === request.user.display_name && request.gdpr_customer && request.content_type !== 'onwardtravel'"
              caption
            >
              Requester / Traveller
            </q-item-label>
            <q-item-label
              v-else
              caption
            >
              Requester
            </q-item-label>
          </q-item-section>
        </q-item>

        <template v-if="request.content_type === 'onwardtravel'">
          <q-item v-if="request.passenger_name" class="item">
            <q-item-section avatar>
              <q-avatar
                inverted
                icon="person"
                class="text-white"
                :class="`bg-${getTravelContentColor(request.content_type)}`"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label label>
                {{ request.passenger_name }}
              </q-item-label>
              <q-item-label caption>
                {{ $t('passenger_name') }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item v-if="request.passenger_name" class="item">
            <q-item-section avatar>
              <q-avatar
                inverted
                icon="phone"
                class="text-white"
                :class="`bg-${getTravelContentColor(request.content_type)}`"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label label>
                {{ request.phone_number }}
              </q-item-label>
              <q-item-label caption>
                {{ $t('mobile_number') }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>

        <q-item
          v-if="request.contact_number"
          class="item"
        >
          <q-item-section avatar>
            <q-avatar
              inverted
              icon="phone"
              class="bg-primary text-white"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label label>
              {{ request.contact_number }}
            </q-item-label>
            <q-item-label caption>
              Contact number
            </q-item-label>
          </q-item-section>
        </q-item>

        <q-item
          v-if="request.contact_email"
          class="item"
        >
          <q-item-section avatar>
            <q-avatar
              inverted
              icon="email"
              class="bg-primary text-white"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label label class="email-address">
              {{ request.contact_email }}
            </q-item-label>
            <q-item-label caption>
              Contact email
            </q-item-label>
          </q-item-section>
        </q-item>

        <q-item
          v-if="request.requester.display_name !== request.user.display_name"
          class="item"
        >
          <q-item-section avatar>
            <q-avatar>
              <img :src="request.user.picture">
            </q-avatar>
          </q-item-section>
          <q-item-section>
            <q-item-label label>
              {{ request.user.display_name }}
            </q-item-label>
            <q-item-label caption>
              Traveller
            </q-item-label>
          </q-item-section>
        </q-item>

        <q-item
          v-if="request.required_by && !request.closed_at"
          class="item"
        >
          <q-item-section avatar>
            <q-avatar
              inverted
              icon="alarm"
              class="bg-primary text-white"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label label>
              {{ request.required_by.date | date }}
            </q-item-label>
            <q-item-label caption>
              Required by
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <!-- MAIN PAGE CONTENT -->
    <div v-if="request" class="layout-padding">
      <div class="row q-col-gutter-md">
        <!-- IF ONWARD TRAVEL -->
        <div v-if="request.content_type === 'onwardtravel'" class="col-xs-12 col-sm-12">
          <div class="row q-col-gutter-md">
            <!-- REQUEST DETAILS -->
            <div class="col-12 col-xl-6">
              <m-group-title
                class="title"
                label="Request Details"
                columns="null"
              />
              <q-card class="">
                <q-card-section>
                  <div class="row q-col-gutter-sm">
                    <div class="col-6">
                      <q-item-label>
                        {{ $t('pick_up_location') }}
                      </q-item-label>
                      <q-item-label caption>
                        {{ request.summary.start_location }}
                      </q-item-label>
                      <q-item-label caption>
                        {{ request.summary.start_postcode }}
                      </q-item-label>
                    </div>
                    <div class="col-6">
                      <q-item-label>
                        {{ $t('drop_off_location') }}
                      </q-item-label>
                      <q-item-label caption>
                        {{ request.summary.end_location }}
                      </q-item-label>
                      <q-item-label caption>
                        {{ request.summary.end_postcode }}
                      </q-item-label>
                    </div>
                    <div class="col-6">
                      <q-item-label>
                        {{ $t('date.date_time_of_pick_up') }}
                      </q-item-label>
                      <q-item-label caption>
                        {{ request.required_by.date | date }}
                      </q-item-label>
                    </div>
                    <div class="col-6">
                      <q-item-label>
                        {{ $t('number_of.passengers') }}
                      </q-item-label>
                      <q-item-label caption>
                        {{ request.summary.number_of_passengers }}
                      </q-item-label>
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </div>
            <!-- EXTRA PASSENGER DETAILS -->
            <div class="col-12 col-xl-6">
              <m-group-title
                class="title"
                label="Extra Passenger Details"
                columns="null"
              />
              <q-card class="">
                <q-card-section>
                  <div class="row q-col-gutter-sm">
                    <div class="col-4">
                      <q-item-label>
                        Business Area
                      </q-item-label>
                      <q-item-label caption>
                        {{ request.summary.business_area }}
                      </q-item-label>
                      <q-item-label class="xl" caption>
                        <br>
                      </q-item-label>
                    </div>
                    <div class="col-4">
                      <q-item-label>
                        Cost Centre
                      </q-item-label>
                      <q-item-label caption>
                        {{ request.summary.cost_centre }}
                      </q-item-label>
                    </div>
                    <div class="col-4">
                      <q-item-label>
                        Distance in Miles
                      </q-item-label>
                      <q-item-label caption>
                        {{ request.summary.distance_in_miles }}
                      </q-item-label>
                    </div>
                    <div class="col-4">
                      <q-item-label>
                        {{ $t('onwardtravel.customer_reference') }}
                      </q-item-label>
                      <q-item-label caption>
                        {{ request.summary.customer_reference || 'N/A' }}
                      </q-item-label>
                    </div>
                    <div class="col-4">
                      <q-item-label>
                        {{ $t('rac.vehicle.reg') }}
                      </q-item-label>
                      <q-item-label caption>
                        {{ request.summary.vehicle_registration }}
                      </q-item-label>
                    </div>
                    <div class="col-4">
                      <q-item-label>
                        {{ $t('rac.vehicle.type') }}
                      </q-item-label>
                      <q-item-label caption>
                        {{ request.summary.vehicle_type }}
                      </q-item-label>
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </div>
            <!-- INSTRUCTIONS FOR DRIVER (IF PRESENT) -->
            <div v-if="request.summary.instructions_for_driver || request.summary.instructions" class="col-12">
              <m-group-title
                class="title"
                label="Instructions to Driver"
                columns="null"
              />
              <q-card class="">
                <q-card-section>
                  <div>{{ request.summary.instructions_for_driver || request.summary.instructions }}</div>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </div>

        <!-- IF NOT ONWARD TRAVEL -->
        <div v-if="request.content_type !== 'onwardtravel'" class="col-xs-12 col-sm-12">
          <m-group-title
            class="title"
            label="Message"
            columns="null"
          />
          <q-card class="bg-white">
            <q-card-section>
              <div class="message">
                <m-editor-viewer :content="request.message" />
              </div>
            </q-card-section>
          </q-card>
        </div>

        <!-- DOCUMENTS -->
        <images
          v-if="request.documents.data.length"
          :images="request.documents.data"
        />
        <!-- NOTES -->
        <div class="col-xs-12 col-sm-12">
          <request-notes
            :notes="request.notes"
            :booking="['amendment', 'cancellation'].includes(request.type) ? request.booking : null"
          />
        </div>
        <!-- PAYMENTS -->
        <div class="col-xs-12 col-sm-12">
          <payments
            :payments="request.payments"
            :request-reference="request.reference"
            :card="true"
          />
        </div>
        <!-- PURCHASE INVOICES -->
        <div class="col-xs-12 col-sm-12">
          <invoices
            :invoices="request.invoices || []"
            :request-reference="request.reference"
          />
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import date from 'utils/date-time'
import { mapGetters } from 'vuex'
import travelContents from 'mixins/travelContents'
import requestNotes from './notes'
import invoices from './invoices'
import flow from './flow'
import images from './images.vue'
import loading from 'utils/loading'
import payments from './payments'
import contentType from '../../../mixins/contentType'

export default {
  components: {
    requestNotes,
    payments,
    invoices,
    flow,
    images
  },
  filters: {
    date (data) {
      return date.toShortDateTime(data)
    }
  },
  mixins: [travelContents, contentType],
  props: {
    reference: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      users: [],
      assignee: {},
      sidebarOpen: true
    }
  },
  computed: {
    ...mapGetters({
      ui: 'ui',
      getRequest: 'requests/getResource',
      partner: 'partner'
    }),
    request () {
      return this.getRequest(this.reference)
    },
    schedule () {
      return this.request?.pricingschedule?.data[this.request?.content_type] || null
    },
    fee () {
      /** Not ideal fix, logic here should have a sensible default. */
      if (this.schedule === null) {
        return 'No fee'
      }

      const percentage = this.schedule.percentage
      const minimumAmount = this.schedule.min.amount

      if (percentage === 0 && minimumAmount === 0) {
        return 'No Fee'
      } else if (percentage !== 0 && minimumAmount === 0) {
        return `${percentage}% Fee, no minimum`
      } else if (percentage === 0 && minimumAmount !== 0) {
        return `Flat Fee - ${this.schedule.min.text}`
      } else if (percentage !== 0 && minimumAmount !== 0) {
        return `${percentage}% Fee, ${this.schedule.min.text} minimum`
      }
      return 'No Fee'
    },
    contentName () {
      return this.getNiceName(this.request.content_type)
    }
  },
  watch: {
    reference (val) {
      if (val) this.loadRequest()
    }
  },
  created () {
    this.loadRequest()
    if (!this.ui.quasar.mini.mode && window.innerWidth > 768) {
      this.$store.dispatch('toggleSidebar')
    }
  },
  methods: {
    loadRequest () {
      if (this.reference) {
        if (!this.getRequest(this.reference)) {
          loading.start({
            message: `Loading request ${this.reference}`,
            partnerSlug: this.partner.slug,
            spinnerColor: this.partner.hex
          })
        }
        this.$store.dispatch('requests/loadResource', {
          id: this.reference
        }).finally(loading.stop())
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.required-by
  margin-left auto

.content-type
  position relative
  cursor pointer
.content-type.selected i, .content-type.selected h5
  color white!important
.content-type h5
  width 100%
  margin-left 15px

.icon
  font-size 36px!important
  margin-top 0px
.content-type .row > div
  text-transform capitalize
.item
  padding 0
  margin-top 10px
.editor-actions
  padding 10px
  display: flex
  justify-content space-between

.key-info
  padding 15px
</style>

<style lang="stylus">
.editor .ql-editor
  min-height 200px
.editor .ql-container
  border-left 0
  border-right 0

.request-details-header
  display: flex
  justify-content space-between

.capitalize
  text-transform capitalize
.message
  max-width 768px
  margin auto

.message h5
  padding 0px 15px

.grid
  display grid
  height 100%
  grid-template-columns 1fr 1fr

.email-address
  word-break break-all

.key-info
  border-bottom 1px lightgrey solid

</style>
