<template>
  <div class="col-xs-12 col-sm-12">
    <m-group-title
      class="title"
      label="Images from the customer"
      columns="null"
    />
    <q-card class="bg-white">
      <q-card-section>
        <div class="row q-col-gutter-sm">
          <div
            v-for="(img, i) in images"
            :key="i"
            class="col-sm-2 img-container"
          >
            <img
              :src="img.url"
              class="uploaded-img"
              @click="() => {
                showLarge = true
                selectedImage = img
              }"
            >
            <small class="image-name">{{ img.filename }}</small>
          </div>
        </div>
      </q-card-section>
    </q-card>
    <m-image-modal v-model="showLarge" :image="selectedImage" />
  </div>
</template>

<script>
export default {
  props: ['images'],
  data () {
    return {
      showLarge: false,
      selectedImage: null
    }
  }
}
</script>

<style lang="stylus" scoped>
.img-container
  height 200px
.uploaded-img
  cursor pointer
  width 100%
  height 90%
  object-fit cover
.image-name
  overflow hidden
  white-space nowrap
</style>
