<template>
  <form class="row q-col-gutter-sm">
    <div class="col-sm-12">
      <m-group-title label="Booking information" />
      <q-card class="bg-white">
        <q-card-section>
          <iata-select
            v-model="booking.airport_iata"
            label="Airport"
          />

          <datetime-picker
            v-model="booking.arrive_at"
            label="Arrive at"
          />
        </q-card-section>
      </q-card>
    </div>
  </form>
</template>

<script>
import datetimePicker from './shared/datetime-picker'
import iataSelect from './shared/iata-select'

export default {
  name: 'BookingFormLounge',
  components: {
    datetimePicker,
    iataSelect
  },
  props: {
    value: Object
  },
  data () {
    return {
      booking: {
        content_type: {
          label: 'Lounge',
          value: 'lounge'
        },
        airport_iata: {
          label: null,
          value: null
        },
        arrive_at: {
          label: null,
          value: null
        }
      }
    }
  },
  watch: {
    booking: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      Object.keys(this.value).forEach(key => {
        this.booking[key] = this.value[key]
      })
      this.booking.content_type = { label: null, value: 'lounge' }
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
