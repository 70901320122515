<template>
  <div class="absolute" style="right: 30px; bottom: 18px">
    <q-btn
      round
      type="button"
      color="primary"
      size="lg"
      icon="done"
      @click="confirmation = true"
    />

    <m-error-dialog v-model="error" :error="errorResponse" />

    <q-dialog
      v-model="confirmation"
      @ok="submit"
    >
      <q-card style="width: 800px; max-width: 75vw">
        <q-card-section>
          <div class="text-h6">
            Confirm the details entered for this booking
          </div>
        </q-card-section>
        <q-card-section v-if="booking.content_type && booking" style="min-width: 768px;">
          <component :is="type" :booking="booking" />
          <div class="text-h6">
            Additional Information
          </div>
          <m-editor-viewer :content="note" />
          <div class="text-h6">
            Invoice Items
          </div>

          <q-item v-for="(it, i) in invoiceItems" :key="i" class="invoice-item">
            <q-item-section>
              <q-item-label label>
                Quantity
              </q-item-label>
              <q-item-label caption>
                {{ it.quantity }}
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label label>
                Description
              </q-item-label>
              <q-item-label caption>
                {{ it.description }}
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label label>
                Unit Price
              </q-item-label>
              <q-item-label caption>
                {{ it.unit_price !== '' && it.unit_price !== null ? (it.unit_price /100).toFixed(2) : null }}
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label label>
                Tax Rate
              </q-item-label>
              <q-item-label caption>
                {{ it.tax_rate }}
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label label>
                Includes Tax
              </q-item-label>
              <q-item-label caption>
                {{ it.unit_price_includes_tax }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn v-close-popup flat label="Cancel" />
          <q-btn color="green" label="Submit" @click="submit" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import processInvoice from '../../../invoice/process-invoice'
import _ from 'lodash'
import rental from './confirmations/rental'
import flight from './confirmations/flight'
import hotel from './confirmations/hotel'
import ridehailing from './confirmations/ridehailing'
import rail from './confirmations/rail'
import chauffeur from './confirmations/chauffeur'
import lounge from './confirmations/lounge'
import parking from './confirmations/parking'
import eurostar from './confirmations/eurostar'
import locallink from './confirmations/locallink'
import carclub from './confirmations/carclub'
import invoiceItem from 'pages/request/invoice/invoice-item-read'

function deformatBooking (booking) {
  return _.cloneDeepWith(booking, (item, key) => {
    if (item && typeof item === 'object' && item.hasOwnProperty('value') && item.hasOwnProperty('label')) {
      return item.value
    }
  })
}

export default {
  components: { invoiceItem },
  props: {
    booking: Object,
    invoiceItems: Array,
    note: String
  },
  data () {
    return {
      error: false,
      errorResponse: null,
      confirmation: false,
      rental,
      flight,
      hotel,
      ridehailing,
      rail,
      chauffeur,
      lounge,
      parking,
      eurostar,
      locallink,
      carclub
    }
  },
  computed: {
    type () {
      return this[this.booking.content_type.value]
    }
  },
  methods: {
    submit () {
      const apiBooking = deformatBooking(this.booking)
      apiBooking.invoices = [processInvoice(this.invoiceItems)]
      apiBooking.note = this.note

      this.$store.dispatch('requests/createBooking', {
        requestReference: this.$route.params.reference,
        booking: _.pickBy(apiBooking, val => val !== null && val !== '')
      })
        .then(() => {
          this.$q.notify({
            message: `Booking created`,
            color: 'positive'
          })
          this.$router.push({ name: 'dashboard' })
        })
        .catch(e => {
          this.error = true
          this.errorResponse = e.data
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-message
  white-space pre

.invoice-item
  display grid
  grid-template-columns 20% 20% 20% 20% 20%
  font-weight 400
  align-items baseline
</style>
