<template>
  <q-dialog
    v-model="open"
    class="dialog confirmation-modal"
  >
    <q-card style="max-width: 75vw; width: 700px;">
      <q-card-section>
        <h5 v-text="$t('manual_booking.confirmation_modal.title')" />
      </q-card-section>
      <q-markup-table separator="horizontal" class="field-table" flat bordered>
        <thead>
          <tr>
            <th class="text-left" v-text="$t('manual_booking.confirmation_modal.field')" />
            <th class="text-left" v-text="$t('manual_booking.confirmation_modal.input')" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-text="$t('content_type.title')" />
            <td>
              <q-badge outline color="primary">
                {{ request.content_type || request.booking && request.booking.content_type }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.requester">
            <td v-text="$t('requester')" />
            <td>
              <q-badge outline color="primary">
                {{ request.requester.label }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.user">
            <td v-text="$tc('traveller')" />
            <td>
              <q-badge outline color="primary">
                {{ request.user.label }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.passenger_name">
            <td v-text="$t('passenger_name')" />
            <td>
              <q-badge outline color="primary">
                {{ request.passenger_name }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.vehicle_registration">
            <td v-text="$t('rac.vehicle.reg')" />
            <td>
              <q-badge outline color="primary">
                {{ request.vehicle_registration }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.preferred_payment_method && request.content_type !== 'onwardtravel'">
            <td v-text="$t('payment_method')" />
            <td>
              <q-badge outline color="primary">
                {{ request.preferred_payment_method }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.contact_number">
            <td>{{ request.content_type === 'onwardtravel' ? $t('onwardtravel.passenger_mobile_number') : $t('contact.number') }}</td>
            <td>
              <q-badge outline color="primary">
                {{ request.contact_number }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.contact_email">
            <td v-text="$t('contact.email')" />
            <td>
              <q-badge outline color="primary">
                {{ request.contact_email }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.pickup_time">
            <td v-text="$t('onwardtravel.required_by')" />
            <td>
              <q-badge outline color="primary">
                {{ request.pickup_time | date }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.customer_reference">
            <td v-text="$t('onwardtravel.customer_reference')" />
            <td>
              <q-badge outline color="primary">
                {{ request.customer_reference }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.start_location">
            <td v-text="$t('pick_up_location')" />
            <td>
              <q-badge outline color="primary">
                {{ request.start_location.label }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.start_postcode">
            <td v-text="$t('pick_up_postcode')" />
            <td>
              <q-badge outline color="primary">
                {{ request.start_postcode }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.end_location">
            <td v-text="$t('drop_off_location')" />
            <td>
              <q-badge outline color="primary">
                {{ request.end_location.label }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.end_postcode">
            <td v-text="$t('drop_off_postcode')" />
            <td>
              <q-badge outline color="primary">
                {{ request.end_postcode }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.distance_in_miles">
            <td v-text="$t('onwardtravel.estimated_distance_miles')" />
            <td>
              <q-badge outline color="primary">
                {{ request.distance_in_miles }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.number_of_passengers">
            <td v-text="$t('number_of.passengers')" />
            <td>
              <q-badge outline color="primary">
                {{ request.number_of_passengers }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.cost_centre">
            <td v-text="$t('cost_centre')" />
            <td>
              <q-badge outline color="primary">
                {{ request.cost_centre }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.business_area">
            <td v-text="$t('rac.business_area')" />
            <td>
              <q-badge outline color="primary">
                {{ request.business_area }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.vehicle_type">
            <td v-text="$t('rac.vehicle.type')" />
            <td>
              <q-badge outline color="primary">
                {{ request.vehicle_type }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.instructions_for_driver">
            <td v-text="$t('onwardtravel.driver_notes.label')" />
            <td>
              <q-badge outline color="primary">
                {{ request.instructions_for_driver }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.internal_notes">
            <td v-text="$t('onwardtravel.internal_notes.label')" />
            <td>
              <q-badge outline color="primary">
                {{ request.internal_notes }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.journey_reference || request.journey_name">
            <td v-text="$tc('journey.journey')" />
            <td>
              <q-badge outline color="primary">
                {{ request.journey_reference || request.journey_name }}
              </q-badge>
            </td>
          </tr>
          <tr v-if="request.message">
            <td v-text="$t('request_information')" />
            <td>
              <q-badge outline color="primary">
                {{ request.message }}
              </q-badge>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions>
        <q-btn
          v-close-popup
          flat
          label="Make changes"
        />
        <q-btn
          v-close-popup
          flat
          color="primary"
          label="Confirm"
          @click="submit"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import date from 'utils/date-time'
import requestSubmit from '../../new/submit'
export default {
  filters: {
    date (data) {
      return date.toShortDateTime(data)
    }
  },
  props: {
    type: String,
    request: Object,
    value: Boolean
  },
  computed: {
    open: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    submit () {
      const request = {
        ...this.request
      }

      if (request.type !== 'Booking') {
        request.content_type = this.request.booking.content_type
        request.booking = this.request.booking.value
      }

      if (request.start_location && typeof request.start_location === 'object') request.start_location = request.start_location.label
      if (request.end_location && typeof request.end_location === 'object') request.end_location = request.end_location.label

      requestSubmit(request)
    }
  }
}
</script>

<style lang="stylus">
.field-table thead th
.field-table tbody td
.field-table .q-badge
  font-size 1rem
.field-table .q-badge
  padding: 6px
.confirmation-modal .modal-content
  width 70vw
  max-width 800px!important
.confirmation-modal .modal-body
  height 40vh
  max-height 300px
.dialog .content
  width 20%
  text-transform capitalize
</style>
