<template>
  <div class="row q-col-gutter-sm">
    <template v-for="c in contentTypes">
      <div
        v-if="hasPermission('can.book.for.anyone') || hasPermission(`book.${c.value}`)"
        :key="c.value"
        class="col-xs-6 col-sm-3"
      >
        <q-card
          v-ripple
          :class="type === c.value ? `bg-${getTravelContentColor(c.value)} selected` : 'bg-white'"
          class="content-type"
          @click.native="() => type = c.value"
        >
          <q-card-section>
            <div class="row">
              <div class="col-xs-2 icon">
                <m-icons-type
                  :inverted="true"
                  :type="c.value"
                  size="inherit"
                />
              </div>
              <div class="col-xs-10">
                <h5 class="text-faded">
                  {{ c.label }}
                </h5>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </template>
  </div>
</template>

<script>
import { ondemand as contentTypes } from 'store/contentTypes'
import authentication from 'mixins/authentication'
import travelContents from 'mixins/travelContents'
export default {
  mixins: [travelContents, authentication],
  props: {
    value: String
  },
  data () {
    return {
      contentTypes
    }
  },
  computed: {
    type: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.content-type
  position relative
  cursor pointer
.content-type.selected i, .content-type.selected h5
  color white!important
.content-type h5
  width 100%
  text-align right

.icon
  font-size 36px!important
.content-type
  .row > div
    display flex
    align-items center

@media screen and (min-width: 1300px)
  .icon
    font-size 60px!important
  .content-type h5
    text-align right
</style>
