<template>
  <div class="purchase-invoices">
    <div class="invoices-title">
      <m-group-title
        class="title"
        label="Purchase Invoices"
        columns="null"
      />
      <q-btn
        v-if="requestReference"
        flat
        label="Add Invoice"
        @click="modal = true"
      />
    </div>
    <div class="invoices-wrapper">
      <purchase-invoice
        v-for="(invoice, i) in invoices"
        :key="invoice.number"
        v-model="invoices[i]"
        type="purchase"
        class="purchase-invoice"
      />
    </div>
    <q-card v-if="!invoices.length" class="bg-white">
      <q-card-section>
        <m-empty-state
          :style="{padding: '30px', margin: 'auto'}"
          icon="note"
        >
          Add purchase invoices to this request
        </m-empty-state>
      </q-card-section>
    </q-card>

    <q-dialog
      v-model="modal"
      class="purchase-invoice-modal"
    >
      <q-card style="min-width: 70vw; max-width: 1350p; max-height: 80vh;">
        <q-card-section>
          <div class="text-h6">
            Add an invoice
            <q-btn
              flat
              label="Add Line"
              @click="addLine"
            />
          </div>
        </q-card-section>
        <q-card-section>
          <invoice-edit ref="invoice" v-model="invoiceItems" type="purchase" />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            color="primary"
            label="Create"
            @click="addInvoice"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import purchaseInvoice from '../invoice/purchase-invoice'
import invoiceEdit from '../invoice/invoice'
import processInvoice from '../invoice/process-invoice'

export default {
  components: {
    purchaseInvoice,
    invoiceEdit
  },
  props: ['invoices', 'requestReference'],
  data () {
    return {
      modal: false,
      invoiceItems: []
    }
  },
  methods: {
    addInvoice () {
      const items = processInvoice(this.invoiceItems).items
      this.$store.dispatch('requests/addInvoice', {
        request: this.requestReference,
        invoice: items
      })
        .then(() => {
          this.modal = false
          this.invoiceItems = []
        })
    },
    addLine () {
      this.$refs.invoice.addItem()
    }
  },
  validations: {
  }
}
</script>

<style lang="stylus" scoped>
.invoices-title
  display flex
  justify-content space-between

.invoices-title .title
  margin-top 10px

.invoices-wrapper
  max-width calc(100vw - 32px)
  overflow auto

.purchase-invoice
  min-width 900px
  @media (min-width: 768px)
    min-width 800px
</style>
