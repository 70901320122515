import { post } from 'api/requests'
import router from 'router'
import date from 'utils/date-time'

export default function submit (data) {
  const req = {
    ...data,
    requester: data.requester.value,
    user: data.user ? data.user.value : data.requester.value,
    required_by: date.toCivilDateTime(data.required_by) + ':00'
  }
  post(req).then(res => {
    router.push('/request/' + res.data.reference)
  })
}
