<template>
  <div>
    <q-btn
      round
      type="button"
      color="primary"
      size="lg"
      class="absolute"
      icon="done"
      style="right: 30px; bottom: 18px"
      @click="submit"
    />
    <m-error-dialog v-model="error" :error="errorResponse" />
  </div>
</template>

<script>
import processInvoice from '../../../invoice/process-invoice'
export default {
  props: {
    booking: Object,
    invoiceItems: Array,
    note: String
  },
  data () {
    return {
      error: false,
      errorResponse: null
    }
  },
  methods: {
    submit () {
      this.booking.invoices = [processInvoice(this.invoiceItems)]

      this.booking.note = this.note

      this.$store.dispatch('requests/amendBooking', {
        reference: this.$route.params.reference,
        bookingReference: this.$route.params.booking,
        booking: this.booking
      })
        .then(() => {
          this.$q.notify({
            message: `Booking amended`,
            type: 'positive'
          })
          this.$router.push({ name: 'dashboard' })
        })
        .catch(e => {
          this.error = true
          this.errorResponse = e.data
        })
    }
  }
}
</script>

<style lang="stylus">
.modal-message
  white-space pre
</style>
