<template>
  <div class="request-flow bg-white">
    <div class="text-center">
      <div class="assignee">
        <div v-if="request.assignee" class="assigned">
          <h4 class="assignee-name">
            {{ request.assignee.display_name }}
            <q-btn
              round
              flat
              dense
              size="sm"
              icon="person_add"
              @click="() => assigneeDialog = true"
            />
          </h4>
          <sub>is assigned to this request</sub>
        </div>
        <div v-else class="unassigned">
          <action-dropdown
            label="Assign SDC"
            @primaryAction="() => assigneeDialog = true"
            @close="() => closeDialog = true"
          >
            <q-item clickable @click.native="assignToMe">
              <q-item-section>
                Assign to me
              </q-item-section>
            </q-item>
          </action-dropdown>
          <sub>This request should be assigned</sub>
        </div>
      </div>

      <template v-if="request.assignee">
        <div v-for="(p, i) in path" :key="p.state">
          <flow-item
            v-if="p.state === 'quoted'"
            :item="p"
            :show="i <= currentStateIndex + 1"
            :complete="i <= currentStateIndex"
            :class="i === currentStateIndex ? 'active' : null"
            @closeRequest="() => closeDialog = true"
            @setState="setState"
          >
            <q-item clickable @click.native="openQuoteBuilder">
              <q-item-section>
                Open quote builder
              </q-item-section>
            </q-item>
          </flow-item>
          <flow-item
            v-else
            :item="p"
            :show="i <= currentStateIndex + 1"
            :complete="i <= currentStateIndex"
            :class="i === currentStateIndex ? 'active' : null"
            @closeRequest="() => closeDialog = true"
            @setState="setState"
          />
        </div>
      </template>

      <div v-if="request.status === 'QUOTE-ACCEPTED' || finishedDate">
        <q-separator class="hr" />
        <div v-if="request.status === 'QUOTE-ACCEPTED'" style="padding: 16px 0">
          <action-dropdown
            :label="actionText"
            @primaryAction="action"
            @close="() => closeDialog = true"
          />
        </div>
        <div v-else>
          <sub style="display: block; padding: 16px 0;">
            <br>
            <strong>{{ request.status }}</strong><br>
            {{ finishedDate }}<br>
            This request has been processed
          </sub>
        </div>
      </div>
    </div>

    <set-assignee
      v-model="assigneeDialog"
      :request-reference="request.reference"
      :current-assignee="request.assignee"
    />
    <close-request-dialog
      v-model="closeDialog"
      :request-reference="request.reference"
      @close="closeRequest"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { close } from 'api/requests'
import setAssignee from './set-assignee'
import closeRequestDialog from './close-request'
import date from 'utils/date-time'
import flowItem from './flow-item.vue'
import actionDropdown from './action-dropdown'
import timezoneFilter from 'utils/timezoneFilter'

export default {
  components: { setAssignee, closeRequestDialog, flowItem, actionDropdown },
  props: ['request'],
  data () {
    return {
      assigneeDialog: false,
      closeDialog: false,
      cancelMessage: '',
      historyItems: ['started', 'quoted', 'quote-accepted', 'closed', 'booked', 'booking amended', 'booking cancelled'],
      path: [
        {
          label: 'Start',
          state: 'started',
          info: 'Start to let your colleagues know you are working on this',
          done: 'This request has been started by the assignee. Work is under way'
        },
        {
          label: 'Quoted',
          state: 'quoted',
          info: 'Update to quoted when you have sent a quote to the customer',
          done: 'A quote has been sent to the customer'
        },
        {
          label: 'Quote Accepted',
          state: 'quote-accepted',
          info: 'Update to accepted when the customer has accepted the quote',
          done: 'A quote has been accepted by the customer'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({ userLookup: 'userLookup' }),
    finishedDate () {
      const { completed_at, closed_at } = this.request
      if (completed_at) {
        if (completed_at.timezone === 'UTC') return timezoneFilter(completed_at.date, 'Europe/London')
        return date.toShortDateTime(completed_at.date)
      } else if (closed_at) {
        if (closed_at.timezone === 'UTC') return timezoneFilter(completed_at.date, 'Europe/London')
        return date.toShortDateTime(closed_at.date)
      } else {
        return null
      }
    },
    actionText () {
      if (this.request.type === 'booking' || this.request.type === 'question') {
        return 'Create Booking'
      } else if (this.request.type === 'amendment') {
        return 'Amend Booking'
      } else if (this.request.type === 'cancellation') {
        return 'Cancel Booking'
      }
      return null
    },
    currentStateIndex () {
      return this.historyItems.indexOf(this.request.status.toLowerCase())
    }
  },
  methods: {
    action () {
      if (this.request.type === 'booking' || this.request.type === 'question') {
        if (this.request.content_type === 'other') {
          this.$router.push(`/new/booking`)
        } else {
          this.$router.push(`/request/${this.$route.params.reference}/book`)
        }
      } else if (this.request.type === 'amendment') {
        this.$router.push(`/request/${this.$route.params.reference}/amend/${this.request.booking.reference}`)
      } else if (this.request.type === 'cancellation') {
        this.$router.push(`/request/${this.$route.params.reference}/cancel/${this.request.booking.reference}`)
      }
    },
    setState (state) {
      if (state.toLowerCase() === this.request.status.toLowerCase() || this.finishedDate) return
      this.$store.dispatch('requests/setState', {
        reference: this.request.reference,
        state: {
          name: state,
          description: state
        }
      })
    },
    closeRequest (message) {
      close(this.$route.params.reference, { message })
        .then(() => {
          this.$router.push('/dashboard')
        })
    },
    assignToMe () {
      this.$store.dispatch('requests/setAssignee', {
        requestReference: this.request.reference,
        user: {
          display_name: this.userLookup.label,
          username: this.userLookup.value
        }
      })
    },
    openQuoteBuilder () {
      let routeData = this.$router.resolve('/quote-builder')
      window.open(routeData.href, '_blank')
      window.focus()
    }
  }
}
</script>

<style lang="stylus" scoped>
.request-flow
  padding 15px
  border-bottom 1px solid lightgrey

.assignee
  padding-bottom 16px
.assignee, .claim
  text-align center

.assignee-name
  font-size 24px
</style>
