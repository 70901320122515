<template>
  <form class="request-form row q-col-gutter-sm">
    <m-group-title
      label="Select the content type"
      columns="null"
    />
    <div
      v-if="$v.request.content_type.$error"
      class="title-error"
    >
      <h6>Content type is required</h6>
    </div>
    <div class="col-sm-12">
      <content-type-select
        v-model="request.content_type"
        :set="checkFields(request.content_type)"
      />
    </div>

    <m-group-title label="Customer information" />
    <div class="col-sm-12">
      <q-card class="bg-white">
        <q-card-section>
          <div class="row q-col-gutter-x-sm">
            <div class="col-xs-12 col-sm-4">
              <requester-field
                v-model="request.requester"
                :error="$v.request.requester.$error"
                error-message="This field is required"
              />
            </div>
            <div class="col-xs-12 col-sm-4">
              <user-field
                v-if="!isOnwardtravel"
                v-model="request.user"
                :disable="!request.requester || request.requester.organisation === 'Individual' ? true : false"
                :organisation="request.requester ? request.requester.organisation : null"
              />
              <q-input
                v-else
                v-model="request.passenger_name"
                type="text"
                :label="$t('passenger_name')"
                :error="$v.request.passenger_name.$error"
                error-message="This field is required"
              />
            </div>
            <div v-if="!isOnwardtravel" class="col-xs-12 col-sm-4">
              <q-select
                v-model="request.preferred_payment_method"
                :disable="paymentMethods.length < 2 ? true : false"
                :options="paymentMethods"
                map-options
                emit-value
                :label="$t('preferred_payment_method')"
              />
            </div>
            <div class="col-xs-12 col-sm-4">
              <q-input
                v-model="request.contact_number"
                type="tel"
                :label="isOnwardtravel ? $t('onwardtravel.passenger_mobile_number') : $t('contact.number')"
                :error="$v.request.contact_number.$error"
                :error-message="isOnwardtravel ? 'This field is required' : 'Either phone or email is required'"
              />
            </div>
            <div v-if="isOnwardtravel" class="col-xs-12 col-sm-4">
              <q-input
                v-model="request.vehicle_registration"
                type="text"
                :label="$t('rac.vehicle.reg')"
                :hint="$t('rac.vehicle.reg_hint')"
                :error="$v.request.vehicle_registration.$error"
                error-message="This field is required"
              />
            </div>
            <div v-if="!isOnwardtravel" class="col-xs-12 col-sm-4">
              <q-input
                v-model="request.contact_email"
                type="email"
                :label="$t('contact.email')"
                :error="$v.request.contact_email.$error"
                error-message="Either phone or email is required"
              />
            </div>
            <div class="col-xs-12 col-sm-4">
              <date-picker
                v-model="request.pickup_time"
                :label="$t('onwardtravel.required_by')"
              />
            </div>
            <div v-if="isOnwardtravel" class="col-xs-12 col-sm-4">
              <q-input
                v-model="request.customer_reference"
                :label="$t('onwardtravel.customer_reference')"
                :hint="$t('onwardtravel.customer_reference_hint')"
                :error="$v.request.customer_reference.$error"
                error-message="This field is required"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>

    <template v-if="isOnwardtravel">
      <m-group-title columns="null" :label="$t('booking.information')" />
      <div class="col-sm-12">
        <q-card class="bg-white">
          <q-card-section>
            <div class="row q-col-gutter-x-sm">
              <div class="col-xs-12 col-sm-4">
                <m-location-search
                  v-model="request.start_location"
                  :label="$t('pick_up_location')"
                  :error="$v.request.start_location.$error"
                  error-message="This field is required"
                  @selected="setOrigin"
                />
              </div>
              <div class="col-xs-12 col-sm-4">
                <m-location-search
                  v-model="request.end_location"
                  :label="$t('drop_off_location')"
                  :error="$v.request.end_location.$error"
                  error-message="This field is required"
                  @selected="setDestination"
                />
              </div>
              <div class="col-xs-12 col-sm-4">
                <estimates :route-info="routeInfo" />
              </div>
              <div class="col-xs-12 col-sm-4">
                <q-input
                  v-model="request.number_of_passengers"
                  type="number"
                  :label="$t('number_of.passengers')"
                  :error="$v.request.number_of_passengers.$error"
                  error-message="This field is required"
                />
              </div>
              <div class="col-xs-12 col-sm-4">
                <m-cost-centre
                  v-model="request.cost_centre"
                  :error="$v.request.cost_centre.$error"
                  error-message="This field is required"
                />
              </div>
              <div class="col-xs-12 col-sm-4">
                <q-select
                  v-model="request.business_area"
                  :label="$t('rac.business_area')"
                  :options="businessAreaOptions"
                  :error="$v.request.business_area.$error"
                  error-message="This field is required"
                />
              </div>
              <div class="col-xs-12 col-sm-4">
                <q-select
                  v-model="request.vehicle_type"
                  :label="$t('rac.vehicle.type')"
                  :hint="$t('rac.vehicle.type_hint')"
                  :error="$v.request.vehicle_type.$error"
                  error-message="This field is required"
                  :options="[
                    { label: $t('taxi.vehicle.types.taxi.description'), value: $t('taxi.vehicle.types.taxi.name')},
                    { label: $t('taxi.vehicle.types.mpv.description'), value: $t('taxi.vehicle.types.mpv.name')},
                    { label: $t('taxi.vehicle.types.wav.description'), value: $t('taxi.vehicle.types.wav.name')},
                    { label: $t('taxi.vehicle.types.other.description'), value: $t('taxi.vehicle.types.other.name')}
                  ]"
                  @input="setVehicleType"
                />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </template>

    <template v-if="isOnwardtravel">
      <div class="col-sm-12">
        <m-group-title :label="$t('onwardtravel.driver_notes.label')" />
        <q-card class="bg-white">
          <m-banner :message="$t('onwardtravel.no_personal_info')" :rounded="false" borderless />
          <q-card-section>
            <q-input
              v-model="request.instructions"
              :placeholder="$t('onwardtravel.driver_notes.placeholder')"
              autogrow
            />
          </q-card-section>
        </q-card>
      </div>

      <div class="col-sm-12">
        <m-group-title :label="$t('onwardtravel.internal_notes.label')" />

        <q-card class="bg-white">
          <q-card-section>
            <q-input
              v-model="request.internal_notes"
              :placeholder="$t('onwardtravel.internal_notes.placeholder')"
              autogrow
            />
          </q-card-section>
        </q-card>
      </div>
    </template>

    <template v-if="!isOnwardtravel">
      <m-group-title columns="null" :label="$t('request_information')" />
      <div v-if="$v.request.message.$error" class="title-error">
        <h6 v-text="$t('request_information_required')" />
      </div>
      <div class="col-xs-12">
        <q-card class="bg-white">
          <m-editor
            v-model="request.message"
            :placeholder="$t('manual_booking.notes')"
            class="editor"
          />
        </q-card>
      </div>
    </template>

    <m-group-title columns="null" :label="$t('journey.info')" />
    <div
      v-if="$v.request.journey_name.$error || $v.request.journey_reference.$error"
      class="title-error"
    >
      <h6>Journey name or existing journey are required</h6>
    </div>
    <div class="col-sm-12">
      <q-card class="bg-white">
        <q-card-section>
          <attach-to-journey
            v-model="request.journey"
            :username="request.user ? request.user.value : null"
            suggested
            @change="setJourney"
          />
        </q-card-section>
      </q-card>
    </div>

    <m-group-title v-if="showReasonFor" columns="null" label="Reason for travel" />
    <div v-if="showReasonFor" class="col-sm-12">
      <q-card class="bg-white">
        <q-card-section>
          <q-input v-model="request.reason_for" label="Reason for travel" />
        </q-card-section>
      </q-card>
    </div>
    <q-page-sticky
      position="bottom-right" :offset="[30, 30]"
    >
      <q-btn
        round
        type="button"
        size="lg"
        color="positive"
        icon="assignment_turned_in"
        @click="openDialog"
      />
    </q-page-sticky>
    <confirmation-modal v-model="dialog" :request="request" type="booking" />
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import date from 'utils/date-time'
import attachToJourney from './shared/attach-to-journey'
import { required, requiredIf } from 'vuelidate/lib/validators'
import _ from 'lodash'
import contentTypeSelect from './shared/content-type-select'
import requesterField from './shared/requester-field'
import userField from './shared/user-field'
import confirmationModal from './shared/confirmation-modal'
import datePicker from './shared/date-picker'
import estimates from '../../ondemand/onwardtravel/estimates'
import shapeUser from './shared/shape-user.js'
import MGroupTitle from 'components/MGroupTitle'

// Must be a full function in order to access `this`
const isOnwardtravelValidator = function () { return this.isOnwardtravel }

export default {
  components: {
    MGroupTitle,
    attachToJourney,
    requesterField,
    userField,
    confirmationModal,
    contentTypeSelect,
    datePicker,
    estimates
  },
  props: {
    filledRequester: Object
  },
  data () {
    return {
      request: {
        type: 'Booking',
        content_type: null,
        requester: null,
        user: null,
        message: null,
        preferred_payment_method: null,
        journey_reference: null,
        journey_name: null,
        reason_for: null,
        contact_number: null,
        contact_email: null,
        passenger_name: null,
        price: null,
        supplier: null,
        cost_centre: null,
        pickup_time: date.roundMinutesUp(),
        end_location: null,
        end_postcode: null,
        taxi_company: null,
        vehicle_type: null,
        business_area: null,
        flight_number: null,
        start_location: null,
        start_postcode: null,
        distance_in_miles: null,
        customer_reference: null,
        number_of_passengers: 4,
        vehicle_registration: null,
        instructions: null
      },
      paymentMethods: [],
      dialog: false,
      isOnwardtravel: false,
      businessAreaOptions: [
        'RAC Onward Travel Transport',
        'RAC Onward Travel Transport CP',
        'RAC Employee Patrol',
        'RAC Employee Transport',
        'RAC Breakdown Entitlement exc onward',
        '999 Team Escalations exc onward'
      ]
    }
  },
  computed: {
    ...mapGetters({
      routeInfo: 'onwardtravel/routeInfo',
      driverNotes: 'onwardtravel/driverNotes',
      racDetails: 'onwardtravel/racDetails'
    }),
    showReasonFor () {
      if (!this.user) return false
      return this.user.permissions.includes('can.book.with.reasonfor')
    },
    showCostCentres () {
      if (!this.request.requester || !this.request.requester.organisation) return false
      return this.request.requester.organisation === 'raconwardtravel'
    }
  },
  watch: {
    'request.requester' (val) {
      this.request.user = null
      this.setAllowedPaymentMethods()
      if (val.organisation === 'Individual') {
        this.request.user = val
        this.request.preferred_payment_method = 'card'
      }
    },
    'request.content_type' (val) {
      if (val === 'onwardtravel') {
        this.request.contact_email = null
        this.$store.dispatch('onwardtravel/reset')
      }
    }
  },
  created () {
    if (this.filledRequester) {
      this.request.requester = shapeUser(this.filledRequester)
    }
  },
  methods: {
    setOrigin (val) {
      this.$store.dispatch('onwardtravel/setOrigin', val)
    },
    setDestination (val) {
      this.$store.dispatch('onwardtravel/setDestination', val)
    },
    setVehicleType (val) {
      this.request.vehicle_type = val.value
    },
    setAllowedPaymentMethods () {
      const permissions = this.request.requester.permissions
      const accountPermission = _.find(permissions, p => p === 'payments.account')

      const paymentMethods = [
        {
          label: 'Card',
          value: 'card'
        }
      ]

      if (accountPermission) {
        paymentMethods.push({
          label: 'Account',
          value: 'account'
        })
      }
      this.paymentMethods = paymentMethods
      this.request.preferred_payment_method = 'card'
    },
    setJourney (data) {
      this.request.journey_reference = data.reference
      this.request.journey_name = data.name
    },
    openDialog () {
      if (this.routeInfo && this.routeInfo.distance) this.request.distance_in_miles = this.routeInfo.distance.miles
      this.$v.$touch()
      if (!this.$v.$error) {
        this.dialog = true
      } else {
        this.$q.notify('Please check the form for validation messages')
      }
    },
    checkFields (value) {
      if (value === 'onwardtravel') {
        this.isOnwardtravel = true
      }
    }
  },
  validations: {
    request: {
      content_type: { required },
      requester: { required },
      user: {
        requiredIf: requiredIf(vm => {
          return vm.passenger_name === null || vm.passenger_name === ''
        })
      },
      passenger_name: {
        requiredIf: requiredIf(vm => {
          return vm.user === null || vm.user === ''
        })
      },
      message: {
        requiredIf: requiredIf(vm => {
          return (vm.customer_reference === '' || vm.customer_reference === null)
        })
      },
      preferred_payment_method: {
        requiredIf: requiredIf(vm => {
          return vm.passenger_name === null || vm.passenger_name === ''
        })
      },
      contact_number: {
        requiredIf: requiredIf(vm => {
          return vm.contact_email === null || vm.contact_email === ''
        })
      },
      contact_email: {
        requiredIf: requiredIf(vm => {
          return (vm.contact_number === null || vm.contact_number === '') && !vm.isOnwardtravel
        })
      },
      journey_name: {
        requiredIf: requiredIf(vm => {
          return vm.journey_reference === null || vm.journey_reference === ''
        })
      },
      journey_reference: {
        requiredIf: requiredIf(vm => {
          return vm.journey_name === null || vm.journey_name === ''
        })
      },
      start_location: { required: requiredIf(isOnwardtravelValidator) },
      end_location: { requiredIf: requiredIf(isOnwardtravelValidator) },
      number_of_passengers: { required: requiredIf(isOnwardtravelValidator) },
      cost_centre: { requiredIf: requiredIf(isOnwardtravelValidator) },
      business_area: { requiredIf: requiredIf(isOnwardtravelValidator) },
      vehicle_type: { requiredIf: requiredIf(isOnwardtravelValidator) },
      customer_reference: { requiredIf: requiredIf(isOnwardtravelValidator) },
      vehicle_registration: { requiredIf: requiredIf(isOnwardtravelValidator) }
    }
  }
}
</script>

<style lang="stylus">
.editor .ql-editor
  min-height 200px
.title-error h6
  color var(--q-color-negative)
  margin 0
  text-transform uppercase
</style>
