<template>
  <form class="row">
    <div class="col-sm-12">
      <m-group-title label="Booking information" />
      <q-card class="bg-white">
        <q-card-section>
          <q-input
            v-model="booking.start_location.value"
            type="text"
            stack-label
            label="Start location"
          />

          <q-input
            v-model="booking.vehicle.value"
            type="text"
            stack-label
            label="Vehicle"
          />

          <datetime-picker
            v-model="booking.depart_at"
            label="Depart at"
          />

          <datetime-picker
            v-model="booking.arrive_at"
            label="Return at"
          />

          <q-input
            v-model="booking.supplier_name.value"
            type="text"
            stack-label
            label="Supplier name"
          />

          <q-input
            v-model="booking.supplier_booking_reference.value"
            type="text"
            stack-label
            label="Supplier booking reference"
          />
        </q-card-section>
      </q-card>
    </div>
  </form>
</template>

<script>
import datetimePicker from './shared/datetime-picker'

export default {
  name: 'BookingFormCarClub',
  components: {
    datetimePicker
  },
  props: {
    value: Object
  },
  data () {
    return {
      booking: {
        content_type: {
          value: 'carclub',
          label: 'Car Club'
        },
        start_location: {
          value: null,
          label: null
        },
        vehicle: {
          value: null,
          label: null
        },
        depart_at: {
          value: null,
          label: null
        },
        arrive_at: {
          value: null,
          label: null
        },
        supplier_name: {
          value: null,
          label: null
        },
        supplier_booking_reference: {
          value: null,
          label: null
        }
      }
    }
  },
  watch: {
    booking: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      Object.keys(this.value).forEach(key => {
        this.booking[key] = this.value[key]
      })
      this.booking.content_type = { label: null, value: 'carclub' }
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
