<template>
  <q-dialog
    v-model="open"
    @ok="setAssignee"
    @cancel="() => newAssignee = null"
  >
    <q-card>
      <q-card-section>
        <div class="text-h5 q-pb-md">
          Assign to SDC colleague
        </div>
        <q-item-label caption>
          Start typing and select from the dropdown.
        </q-item-label>
        <q-item-label caption>
          Not appearing? They may not have been added to the system or could be blocked
        </q-item-label>
        <m-select-query
          v-model="assignee"
          label="Set Assignee"
          icon="search"
          :query="autocomplete"
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          v-close-popup
          color="primary"
          label="Set Assignee"
          @click="setAssignee"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { index as searchStaff } from 'api/backoffice'

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    requestReference: {
      type: String,
      required: true
    },
    currentAssignee: {
      type: Object
    }
  },
  data () {
    return {
      newAssignee: null,
      users: []
    }
  },
  computed: {
    open: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    assignee: {
      get () {
        if (!this.newAssignee) {
          return this.currentAssignee ? {
            label: this.currentAssignee.display_name,
            value: this.currentAssignee.username
          } : null
        }
        return this.newAssignee
      },
      set (val) {
        this.newAssignee = val
      }
    }
  },
  methods: {
    autocomplete (val) {
      return searchStaff({ search: val })
        .then((users) => {
          return users.data.map(u => ({
            label: u.display_name,
            value: u,
            avatar: u.picture
          }))
        })
    },
    setAssignee () {
      this.$store.dispatch('requests/setAssignee', {
        requestReference: this.requestReference,
        user: this.assignee.value
      })
      this.newAssignee = null
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-scroll
  min-height 80px

sub
  display block
  margin-bottom 16px

</style>

<style lang="stylus">
.modal-scroll
  min-height 80px

</style>
