<template>
  <form>
    <div class="row q-col-gutter-sm">
      <div class="col-sm-12">
        <m-group-title label="Booking information" />
        <q-card class="bg-white">
          <q-card-section>
            <q-input
              v-model="booking.class.value"
              type="text"
              stack-label
              label="Class"
            />

            <q-input
              :value="booking.ticket_collection_reference ? booking.ticket_collection_reference.value : ''"
              type="text"
              stack-label
              label="Ticket Collection Reference"
              @input="(val) => {
                booking.ticket_collection_reference = {
                  value: val,
                  label: null
                }
              }"
            />
          </q-card-section>
        </q-card>

        <div class="legs-title">
          <m-group-title
            class="title"
            label="Outbound Legs"
            columns="null"
          />
          <q-btn
            flat
            label="Add Leg"
            type="button"
            @click="addLeg('outbound')"
          />
        </div>

        <m-empty-state
          v-if="booking.outbound_legs.length === 0"
          :style="{padding: '30px', margin: 'auto'}"
          icon="transfer_within_a_station"
        >
          Add legs for the outbound journey here
        </m-empty-state>

        <div class="row">
          <rail-leg
            v-for="(l, i) in booking.outbound_legs"
            :key="i"
            v-model="booking.outbound_legs[i]"
            :leg-number="i + 1"
            @removeLeg="() => removeLeg('outbound', i)"
          />
        </div>

        <div class="legs-title">
          <m-group-title
            class="title"
            label="Return Legs"
            columns="null"
          />
          <q-btn
            flat
            type="button"
            label="Add Leg"
            @click="addLeg('return')"
          />
        </div>

        <m-empty-state
          v-if="booking.return_legs.length === 0"
          :style="{padding: '30px', margin: 'auto'}"
          icon="transfer_within_a_station"
        >
          Add legs for the return journey here
        </m-empty-state>

        <div class="row">
          <rail-leg
            v-for="(l, i) in booking.return_legs"
            :key="i"
            v-model="booking.return_legs[i]"
            :leg-number="i + 1"
            @removeLeg="() => removeLeg('return', i)"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash'
import railLeg from './shared/rail-leg'
const legItem = {
  depart_atoc: { label: null, value: null },
  depart_at: { label: null, value: null },
  arrive_atoc: { label: null, value: null },
  arrive_at: { label: null, value: null }
}

export default {
  name: 'BookingFormRail',
  components: {
    railLeg
  },
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      booking: {
        content_type: { label: null, value: 'rail' },
        class: { label: null, value: null },
        ticket_collection_reference: { label: null, value: null },
        outbound_legs: [_.cloneDeep(legItem)],
        return_legs: []
      }
    }
  },
  watch: {
    booking: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      Object.keys(this.value).forEach(key => {
        this.booking[key] = this.value[key]
      })
      this.booking.content_type = { label: null, value: 'rail' }
    }
  },
  methods: {
    addLeg (direction) {
      const property = direction + '_legs'

      this.booking[property].push(_.cloneDeep(legItem))
    },
    removeLeg (direction, index) {
      const property = direction + '_legs'
      this.booking[property].splice(index, 1)
    }
  }
}
</script>

<style lang="stylus" scoped>
.legs-title
  display flex
  justify-content space-between

.legs-title .title
  margin-top 14px

.outbound-leg, .return-leg
  background #f5f5f5
  padding 10px

.leg-title
  display flex
  justify-content space-between
  margin-bottom 10px
  padding 0 10px

.leg-title .title
  margin-top 6px
</style>

<style lang="stylus">
.leg-card .q-card-section
  padding-top 0!important
</style>
