<template>
  <div class="booking-note">
    <m-group-title
      label="Additional Information"
      columns="null"
    />
    <q-card class="bg-white">
      <m-editor
        v-model="body"
        placeholder="This text will be visible to the customer in their booking"
      />
    </q-card>
  </div>
</template>

<script>
export default {
  props: {
    value: String
  },
  computed: {
    body: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="stylus">
.booking-note .ql-container
  border 0
.booking-note .ql-editor
  min-height 200px
</style>
