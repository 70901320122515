<template>
  <div class="row q-col-gutter-sm">
    <div class="col-xs-1">
      {{ value.quantity }}
    </div>
    <div class="col-xs-3">
      {{ value.description }}
    </div>
    <div class="col-xs-1">
      {{ value.unit_price.text }}
    </div>
    <div class="col-xs-2">
      {{ taxRate }}
    </div>
    <div class="col">
      {{ value.net_amount.text }}
    </div>
    <div class="col">
      {{ value.tax_amount.text }}
    </div>
    <div class="col">
      {{ value.gross_amount.text }}
    </div>
  </div>
</template>

<script>
import { selectIndex } from 'store/vat-rates'
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    index: Number
  },
  computed: {
    taxRate () {
      return selectIndex[this.value.tax_rate].label
    }
  }
}
</script>

<style lang="stylus">
label
  display block
  font-weight 600
  margin-bottom 5px
</style>
