<template>
  <div class="row q-col-gutter-sm">
    <div class="col-sm-6">
      <date-field
        :value="value"
        :label="label"
        format="dd/LL/yy"
        @change="datePicked"
      />
    </div>
    <div class="col-sm-6">
      <time-field
        :minute-step="5"
        :value="value"
        label="Time"
        @change="datePicked"
      />
    </div>
  </div>
</template>

<script>
import timeField from '@/MFields/time'
import dateField from '@/MFields/date'
export default {
  components: {
    timeField,
    dateField
  },
  props: {
    label: {
      type: String,
      default: 'Select a date and time'
    },
    value: [Object, Date, String],
    error: Boolean
  },
  methods: {
    datePicked (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style>

</style>
