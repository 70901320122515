<template>
  <form class="row q-col-gutter-sm">
    <div class="col-sm-12">
      <div class="legs-title">
        <m-group-title
          class="title"
          label="Outbound Legs"
          columns="null"
        />
        <q-btn
          flat
          type="button"
          label="Add Leg"
          @click="addLeg('outbound')"
        />
      </div>

      <empty-state
        v-if="booking.outbound_legs.length === 0"
        :style="{padding: '30px', margin: 'auto'}"
        icon="transfer_within_a_station"
      >
        Add legs for the outbound journey here
      </empty-state>

      <div
        v-else
        class="row q-col-gutter-sm"
      >
        <flight-leg
          v-for="(l, i) in booking.outbound_legs"
          :key="i"
          v-model="booking.outbound_legs[i]"
          :leg-number="i + 1"
          @removeLeg="() => removeLeg('outbound', i)"
        />
      </div>
    </div>

    <div class="col-sm-12">
      <div class="legs-title">
        <m-group-title
          class="title"
          label="Return Legs"
          columns="null"
        />
        <q-btn
          flat
          label="Add Leg"
          type="button"
          @click="addLeg('return')"
        />
      </div>

      <m-empty-state
        v-if="booking.return_legs.length === 0"
        :style="{padding: '30px', margin: 'auto'}"
        icon="transfer_within_a_station"
      >
        Add legs for the return journey here
      </m-empty-state>

      <div
        v-else
        class="row q-col-gutter-sm"
      >
        <flight-leg
          v-for="(l, i) in booking.return_legs"
          :key="i"
          v-model="booking.return_legs[i]"
          :leg-number="i + 1"
          @removeLeg="() => removeLeg('return', i)"
        />
      </div>
    </div>
  </form>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import flightLeg from './shared/flight-leg'

const leg = {
  arrive_at: { value: null, label: null },
  arrive_iata: { value: null, label: null },
  depart_at: { value: null, label: null },
  depart_iata: { value: null, label: null },
  flight_number: { value: null, label: null },
  supplier_reference: { value: null, label: null }
}

export default {
  name: 'BookingFormFlight',
  components: {
    flightLeg
  },
  props: {
    value: Object
  },
  computed: {
    booking: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    if (!this.booking.outbound_legs) {
      Vue.set(this.booking, 'outbound_legs', [_.cloneDeep(leg)])
      Vue.set(this.booking, 'return_legs', [])
    }
    Vue.set(this.booking, 'content_type', { label: null, value: 'flight' })
  },
  methods: {
    addLeg (direction) {
      const property = direction + '_legs'
      this.value[property].push(_.cloneDeep(leg))
    },
    removeLeg (direction, index) {
      const property = direction + '_legs'
      this.value[property].splice(index, 1)
    }
  }
}
</script>

<style lang="stylus" scoped>
.legs-title
  display flex
  justify-content space-between

.legs-title .title
  margin-top 14px

</style>

<style lang="stylus">
.leg-card .q-card-section
  padding-top 0!important
</style>
