<template>
  <m-select-query
    v-model="user"
    v-bind="$attrs"
    label="Traveller"
    :query="getUsers"
    clearable
  />
</template>

<script>
import shapeUser from './shape-user'
import { search } from 'api/members'

export default {
  props: {
    value: Object,
    organisation: String,
    error: Boolean
  },
  computed: {
    user: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    getUsers (string, done) {
      return search(this.organisation, {
        query: string
      })
        .then(users => {
          return users.data.map(user => shapeUser(user))
        })
    }
  }
}
</script>

<style>

</style>
