<template>
  <div class="col-sm-12">
    <div v-if="type === 'sales'" class="invoice-title">
      <m-group-title
        :label="`${type} Invoice`"
        class="title"
        columns="null"
      />
      <q-btn
        flat
        label="Add Line"
        @click="addItem"
      />
    </div>
    <div v-for="(i, index) in items" :key="index">
      <invoice-item
        v-if="!i.is_mobilleo_fee"
        :key="index"
        v-model="items[index]"
        :type="type"
        :index="index"
        @remove="removeItem(index)"
      />
      <mobilleo-fee
        v-else
        v-model="items[index]"
        :index="index"
        @remove="removeItem(index)"
      />
    </div>

    <hr>
    <div>
      Net: {{ totals.net.toString() }}
      Tax: {{ totals.tax.toString() }}
      Gross: {{ totals.gross.toString() }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Money from 'js-money'
import invoiceItem from './invoice-item'
import mobilleoFee from './mobilleo-fee'

const item = {
  quantity: 1,
  description: '',
  unit_price: null,
  tax_rate: 'standard',
  currency: 'GBP',
  is_mobilleo_fee: false,
  unit_price_includes_tax: false,

  net: new Money(0, Money['GBP']),
  tax: new Money(0, Money['GBP']),
  gross: new Money(0, Money['GBP'])
}

export default {
  components: {
    invoiceItem,
    mobilleoFee
  },
  props: {
    type: String,
    value: Array
  },
  computed: {
    totals () {
      const totals = {
        net: new Money(0, Money['GBP']),
        tax: new Money(0, Money['GBP']),
        gross: new Money(0, Money['GBP'])
      }

      this.items.map(it => {
        totals.net = totals.net.add(it.net)
        totals.tax = totals.tax.add(it.tax)
        totals.gross = totals.gross.add(it.gross)
      })

      return totals
    },
    items: {
      get () {
        return this.value
      },
      set () {
        this.$emit('input', this.items)
      }
    }
  },
  created () {
    this.items.push(_.cloneDeep(item))
    if (this.type === 'sales') {
      const fee = _.cloneDeep(item)
      fee.is_mobilleo_fee = true
      fee.description = 'Mobilleo Fee'
      fee.quantity = 1
      fee.tax_rate = 'standard'
      this.items.push(fee)
    }
  },

  methods: {
    addItem () {
      if (this.type === 'sales') {
        this.items.splice(this.items.length - 1, 0, _.cloneDeep(item))
      } else {
        this.items.push(_.cloneDeep(item))
      }
    },
    removeItem (index) {
      this.items.splice(index, 1)
    }
  }
}
</script>

<style lang="stylus" scoped>
.invoice-title
  display flex
  justify-content space-between

.invoice-title .title
  margin-top 14px
</style>
