<template>
  <m-select-query
    v-model="location"
    :label="label"
    :placeholder="placeholder"
    icon="directions_car"
    content-class="q-pb-md"
    :query="searchBranches"
    clearable
  />
</template>

<script>
import { locationLookup } from 'api/rental'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        label: null,
        value: null
      })
    },
    label: String,
    placeholder: {
      type: String,
      default: 'Enter a branch...'
    }
  },
  computed: {
    location: {
      get () {
        return this.value.value ? this.value : null
      },
      set (val) {
        if (val) this.$emit('input', val)
      }
    }
  },
  methods: {
    searchBranches: locationLookup
  }
}
</script>

<style>

</style>
