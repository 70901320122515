<template>
  <m-select-query
    v-model="atoc"
    :label="label"
    icon="train"
    :query="searchStations"
  />
</template>

<script>
import { stations } from 'api/train'

export default {
  props: {
    value: {
      type: Object
    },
    label: String,
    placeholder: {
      type: String,
      default: 'Enter a train station...'
    }
  },
  computed: {
    atoc: {
      get () {
        return this.value.value ? this.value : null
      },
      set (val) {
        if (val) this.$emit('input', val)
      }
    }
  },
  methods: {
    searchStations (terms) {
      return stations({ name: terms }).then((response) => {
        return response.data.map(station => {
          return {
            label: station.name,
            value: station.atoc,
            icon: 'train',
            color: 'blue-8',
            append: station.atoc
          }
        })
      })
    }
  }
}
</script>

<style>

</style>
