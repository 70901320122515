<template>
  <div class="row q-col-gutter-x-sm">
    <div class="col-sm-6">
      <date-field
        :value="datetime.value"
        :label="label"
        :min="min"
        format="DDDD"
        @change="v => {
          datetime = v
        }"
      />
    </div>
    <div class="col-sm-6">
      <time-field
        :minute-step="1"
        :value="datetime.value"
        :min="min"
        label="Time"
        @change="(v) => datetime = v"
      />
    </div>
  </div>
</template>

<script>
import date from 'utils/date-time'
import timeField from '@/MFields/time'
import dateField from '@/MFields/date'
export default {
  components: {
    timeField,
    dateField
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        label: null,
        value: null
      })
    },
    label: {
      type: String,
      default: 'Select a date and time'
    },
    min: String
  },
  computed: {
    datetime: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', {
          label: null,
          value: date.toCivilDateTime(value) + ':00'
        })
      }
    }
  }
}
</script>

<style>

</style>
