<template>
  <div class="requests-container">
    <div class="requests">
      <div class="index-list">
        <m-index-list
          :load="load"
          :filters="filters"
          :sort="sort"
          :columns="columns"
          :items="items"
          :reset-items="() => {$store.dispatch('entities/requests/deleteAll')}"
          :item-height="62"
          height="calc(100vh - 243px)"
        >
          <template v-slot:filters>
            <filters v-model="filters" />
          </template>
          <template v-slot:item="{item}">
            <request-card :item="item" @assign="id => requestToAssign = id" />
          </template>
        </m-index-list>
        <set-assignee v-model="requestToAssign" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapValues, pickBy } from 'lodash'
import mIndexList from '@/MIndexList'
import Request from 'store/models/request'
import filters from './filters'
import requestCard from './request-card'
import setAssignee from './set-assignee'
import { mapGetters } from 'vuex'

export default {
  name: 'BackofficeRequests',
  components: {
    mIndexList,
    filters,
    requestCard,
    setAssignee
  },
  data () {
    return {
      requestToAssign: null,
      filters: {
        contentType: null,
        type: null,
        requester: null,
        user: null,
        assignee: null,
        org: null,
        booking: this.$route.query.booking,
        individuals: null,
        closed: null,
        completed: null,
        reference: '',
        createdBetween: null
      },
      sort: {
        column: 'created_at',
        direction: 'desc'
      },
      columns: [
        {
          label: 'Reference'
        },
        {
          label: 'Requested at',
          value: 'created_at',
          sortable: true
        },
        {
          label: 'Status'
        },
        {
          label: 'Type'
        },
        {
          label: 'Organisation'
        },
        {
          label: 'Requester'
        },
        {
          label: 'Traveller'
        },
        {
          label: 'Assignee'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      consumerToken: 'partner/consumerToken'
    }),
    items () {
      return Request.query()
        .orderBy(this.sort.column, this.sort.direction)
        .get()
    }
  },
  watch: {
    consumerToken: {
      deep: true,
      handler () {
        const filters = {
          ...this.filters,
          page: 1,
          limit: 20,
          orderBy: 'created_at:desc'
        }
        this.$store.dispatch('entities/requests/deleteAll')
        this.load(pickBy(filters))
      }
    }
  },
  methods: {
    async load (filters) {
      const filtersToSend = mapValues(filters, f => f.value ? f.value : f)
      return this.$store.dispatch('entities/requests/loadAllRequests', filtersToSend)
    }
  }
}
</script>

<style lang="stylus" scoped>
.top-sheet
  padding 15px
  position relative
  background white
  height 205px
  z-index 100
  @media (max-width 1440px)
    height 285px

.index-head
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr
  width calc(100% - 25px)
  position absolute
  bottom 0
  left 0

.col-head
  position relative
  padding 10px 12px

.sort-icon
  position: absolute;
  top: 8px;
  font-size: 25px;

.col-head.sortable
  cursor pointer
  transition background-color .3s

  &:hover
    background: $grey-300

.dates
  margin-top -17px

.btn-wrapper
  margin-top 12px

.bookings
  width 100%

.checkbox
  margin-top 25px

.checkboxes
  margin-top 24px

.checkboxes > *
  margin-right 32px
</style>
