<template>
  <m-select-query
    v-model="code"
    :label="label"
    icon="train"
    :query="searchStations"
  />
</template>

<script>
import { stations } from 'api/eurostar'
export default {
  props: {
    value: {
      type: Object
    },
    label: String,
    placeholder: {
      type: String,
      default: 'Enter a train station...'
    }
  },
  computed: {
    code: {
      get () {
        return this.value.value ? this.value : null
      },
      set (val) {
        if (val) this.$emit('input', val)
      }
    }
  },
  methods: {
    searchStations (terms) {
      return stations()
        .then(response => {
          var search = terms.toLowerCase()
          var filtered = response.data.filter(station => {
            return station.name.toLowerCase().includes(search) || station.code.toLowerCase().includes(search)
          })
          return filtered.map(station => {
            return {
              label: station.name,
              value: station.code,
              icon: 'train',
              color: 'indigo-10',
              append: station.code.toUpperCase(),
              iata: station.code.toUpperCase()
            }
          })
        })
    }
  }
}
</script>

<style>

</style>
