<template>
  <div>
    <div class="row q-col-gutter-sm tools">
      <div class="col-sm-2 qa-pt-none">
        <q-input
          v-model="filters.reference"
          label="Reference"
        />
      </div>
      <div class="col-sm-2 qa-pt-none">
        <content-type-select
          v-model="filters.content"
          label="Transport type"
        />
      </div>
      <div class="col-sm-2 qa-pt-none">
        <user-lookup
          v-model="filters.requester"
          label="Requester"
        />
      </div>
      <div class="col-sm-2 qa-pt-none">
        <user-lookup
          v-model="filters.user"
          label="Traveller"
        />
      </div>
      <div class="col-sm-2 qa-pt-none">
        <user-lookup
          v-model="filters.assignee"
          role="backoffice"
          label="Assignee"
        />
      </div>
      <div class="col-sm-2 qa-pt-none">
        <org-lookup
          v-model="filters.organisation"
          role="backoffice"
          label="Organisation"
        />
      </div>
    </div>

    <div class="row q-col-gutter-sm tools q-pt-none">
      <div class="col-sm-2 q-pt-none">
        <booking-lookup
          v-model="filters.booking"
        />
      </div>

      <div class="col-sm-2 q-pt-none">
        <m-date-range-select
          label="Requested at"
          @change="filterByDate"
        />
      </div>
      <div class="col-sm-3 q-pt-none row justify-around">
        <q-checkbox v-model="filters.individuals" :false-value="null" :indeterminate-value="false" label="Only individuals" class="checkbox" />

        <q-radio v-model="status" val="all" label="All" />
        <q-radio v-model="status" val="open" label="Open" />
        <q-radio v-model="status" val="closed" label="Closed" />
        <q-radio v-model="status" val="completed" label="Completed" />
      </div>
    </div>
  </div>
</template>
<script>
import date from 'utils/date-time'
import userLookup from '@/MFields/user-lookup'
import contentTypeSelect from '@/MFields/content-type-select'
import orgLookup from '@/MFields/org-lookup'
import bookingLookup from '@/MFields/booking-lookup'

export default {
  components: {
    userLookup,
    contentTypeSelect,
    orgLookup,
    bookingLookup
  },
  props: ['value'],
  data () {
    return {
      filters: {},
      status: 'all'
    }
  },
  watch: {
    status (val) {
      if (val === 'all') {
        this.filters.closed = null
        this.filters.completed = null
      } else if (val === 'open') {
        this.filters.closed = 0
        this.filters.completed = 0
      } else if (val === 'closed') {
        this.filters.closed = 1
        this.filters.completed = 0
      } else if (val === 'completed') {
        this.filters.closed = 0
        this.filters.completed = 1
      }
    },
    filters: {
      deep: true,
      handler (val, old) {
        this.$emit('input', { ...val })
      }
    }
  },
  created () {
    this.filters = { ...this.value }
  },
  methods: {
    filterByDate (dates) {
      this.filters.createdBetween = dates.start && dates.end ? `${date.toCivilDate(dates.start)} 00:00:00,${date.toCivilDate(dates.end)} 23:59:59` : null
    }
  }
}
</script>
<style lang="stylus" scoped>
.tools
  width 100%
</style>
